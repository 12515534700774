import React from "react";
import "./App.css";
import "./index.css";

import WebService from "./Service.js";


import Utils from "./Utils.js";




export default
class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transactions : null,
            formFailed : false,
            walletHistory : [],
            results : [],
            feeHistory : [],
            isCurrentFeePaid : false

        };
    }

    componentDidMount() {
        let me =  Utils.me();
        if(me && Utils.isMember(me)) {
            WebService.association().then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        this.setState({transactions: data});

                    });
                }
            });


            WebService.feeHistory(me.id).then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        this.setState({feeHistory: data});
                        // if fee of current year or the next year is in array
                        let currentYear = new Date().getFullYear();
                        let nextYear = new Date().getFullYear() + 1;
                        for(let i = 0; i < data.length; i++) {

                            if(data[i].year == currentYear || data[i].year == nextYear) {
                                this.setState({isCurrentFeePaid: true});
                                break;
                            }
                        }

                        WebService.result().then((response) => {
                            if (response.status === 200) {
                                response.json().then(async (data) => {

                                    let results = data;
                                    // remove if membersFee, moneyResult, of totalEvents is 0
                                    results = results.filter((result) => {
                                        return result.membersFee > 0 || result.moneyResult > 0 || result.totalEvents > 0;
                                    });

                                    for(let i = 0; i < results.length; i++) {
                                        let result = results[i];
                                        // Check if pdf return ok 200
                                        let pdfResponse = await fetch(result.resultPdfPath, {method: 'GET'});
                                        if(pdfResponse.status !== 200) {
                                            result.resultPdfPath = false;
                                        }

                                    }

                                    // sort by year
                                    results = results.sort((a, b) => b.year - a.year);
                                    this.setState({results: results});
                                });
                            }
                        });
                        
                    });
                }
            });


            WebService.walletHistory(me.id).then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        this.setState({walletHistory: data});
                    });
                }
            });


        }
    }

    render() {

        let me =  Utils.me();
        // solde réel
        let solde_reel = this.state.walletHistory.filter(w => w.validated).reduce((total, wallet) => total + wallet.amount, 0);
        // solde corrigé
        let solde_corrigé = this.state.walletHistory.reduce((total, wallet) => total + wallet.amount, 0);
        // solde en cours
        let solde_cours = this.state.walletHistory.filter(w => !w.validated).reduce((total, wallet) => total + wallet.amount, 0);
        solde_reel = Math.round(solde_reel * 100) / 100;
        solde_corrigé = Math.round(solde_corrigé * 100) / 100;
        solde_cours = Math.round(solde_cours * 100) / 100;
        
        return (
            <div className="bg-frenchBlue block text-frenchWhite">
                {!me &&
                    <div>
                        <p className="text-xl text-center font-bold"><a className="text-frenchBrown underline"href="/login">Connectez-vous</a> pour accéder aux informations de l'association</p>
                    </div>
                }

                {((!Utils.isMember(me)) || Utils.isGuest(Utils.me())) &&
                <div>
                    <p className="text-xl text-center bg-badColor inline-block  rounded p-1font-bold">Vous devez être membre pour accéder aux informations de l'association</p>
                </div>
                }
                
                {(Utils.isMember(me) && !Utils.isGuest(me)) &&
                    <div className="font-bold text-frenchWhite bg-frenchBlack/50 mb-4 border-solid border-t-2 border-b-2  border-frenchBrown ml-4 pt-4 mr-4">
                    {/* <hr className="border-frenchBrown border-2 ml-4 mr-4" /> */}
                    <h2 className="text-frenchWhite underline text-2xl text-center p-2">
                        Historique de mes cotisations
                    </h2>
                    {!this.state.isCurrentFeePaid &&
                        <p className="text-frenchWhite text-center bg-badColor rounded inline mb-5 mt-5 p-2">Votre cotisation de cette année n'est pas à jour ! Contactez au plus vite un membre du bureau.</p>
                    }
                    <br></br>
                    {this.state.feeHistory.length === 0 &&
                        <p className="text-frenchWhite text-center p-2 mt-3 mb-3 bg-neutralColor inline-block rounded">
                            Aucun historique de cotisation
                        </p>
                    }
                    {this.state.feeHistory.length > 0 &&
                        <table className="table-auto mx-auto w-3/4">
                            <thead className="border-frenchBrown border-b-2">
                                <tr>
                                    <th className="text-frenchWhite text-center p-2">Année</th>
                                    <th className="text-frenchWhite text-center p-2">Date de mis à jour</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {this.state.feeHistory
                                    .sort((a, b) => b.year - a.year)
                                    .map((fee, index) => {
                                    return (
                                        <tr className="border-frenchBrown" key={index}>
                                            <td className={"text-frenchWhite  p-2 mt-1   text-center rounded inline-block " + (this.state.isCurrentFeePaid && index === 0 ? "bg-goodColor" : "") }>{fee.year}</td>
                                            <td className="text-frenchWhite  p-2  text-center">{new Date(fee.datetime).toLocaleDateString('fr-FR', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute:'numeric'})}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    }
                    

                </div>
                }

                {(Utils.isMember(me) && !Utils.isGuest(me)) &&
                <div>
                    <div className="font-bold text-frenchWhite bg-frenchBlack/50 border-solid border-t-2 border-b-2  border-frenchBrown ml-4 pt-4 mr-4 pt-4">
                        {/* <hr className="border-frenchBrown border-2 ml-4 mr-4" /> */}
                        <h2 className="text-frenchWhite underline text-2xl text-center p-2">
                            Bilans annuels
                        </h2>
                        {!this.state.isCurrentFeePaid &&
                            <p className="text-frenchWhite text-center p-2 bg-neutralColor inline-block rounded mb-3">
                                Vous devez avoir payé votre cotisation pour accéder aux bilans annuels
                            </p>
                        }
                        {(this.state.results.length > 0 && this.state.isCurrentFeePaid) &&
                            <table className="table-auto mx-auto w-3/4">
                                <thead className="border-frenchBrown border-b-2">
                                    <tr>
                                        <th className="text-frenchWhite text-center p-2">Année</th>
                                        <th className="text-frenchWhite text-center p-2">Cotisants</th>
                                        {/* <th className="text-frenchWhite text-center p-2">Résultat</th> */}
                                        <th className="text-frenchWhite text-center p-2">Evènements</th>
                                        <th className="text-frenchWhite text-center p-2">Bilan d'AG (.pdf)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.results.map((result, index) => {
                                        return (
                                            <tr className="border-frenchBrown" key={index}>
                                                <td className="text-frenchWhite  p-2 break-words text-center">{result.year}</td>
                                                <td className="text-center p-2">{result.membersFee} </td>
                                                {/* <td className="text-center p-2">
                                                    {result.moneyResult > 0 ? "+ " : "- "}
                                                    {result.moneyResult} €</td> */}
                                                <td className="text-center p-2">{result.totalEvents}
                                                </td>
                                                <td className="text-center p-2">
                                                    
                                                    {result.resultPdfPath === false &&
                                                        <p className="text-neutralColor">Aucun bilan pour le moment</p>
                                                    }
                                                    {result.resultPdfPath !== false &&
                                                        <a href={result.resultPdfPath} target="_blank" rel="noreferrer" className="text-frenchBrown underline">Télécharger</a>
                                                    }
                                                </td>

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        }
                        {this.state.results.length === 0 &&
                            <p className="text-frenchWhite text-center p-2 bg-neutralColor inline-block rounded">
                                Aucun bilan annuel
                            </p>
                        }
                    </div>

                    {/*
                    <div className="font-bold text-frenchWhite bg-frenchBlack/50 border-solid border-t-2 border-b-2  border-frenchBrown ml-4 pt-4 mt-4 mr-4">
                      
                        <h2 className="text-frenchWhite underline text-2xl text-center p-2">
                            Budget associatif
                        </h2>

                        <div className="">

                        {Utils.isAdmin(me) &&
                                <form className="text-frenchWhite text-xl text-center p-2" onSubmit={(e) => {
                                    e.preventDefault();
                                    let amount = e.target.amount.value;
                                    let description = e.target.description.value;
                                    if(!amount || !description) {
                                        this.setState({formFailed: true});
                                        return;
                                    }
                                    let document = e.target.document.files[0];
                                    this.setState({formFailed: false});
                                    WebService.createAssociationTransaction(amount, description, document).then((response) => {
                                        if (response.status === 200) {
                                            response.json().then((data) => {
                                                let transactionsCopy = this.state.transactions;
                                                transactionsCopy.push(data);
                                                this.setState({transactions: transactionsCopy});
                                                });
                                            }
                                        });
                                    }}>
                                    <input className="shadow appearance-none border rounded w-1/12 py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" type="number" name="amount" placeholder="Montant" />
                                    <input className="shadow appearance-none border rounded w-4/12 py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline"type="text" name="description" placeholder="Description" />
                                    <br></br>
                                    <input className=" appearance-none  rounded w-1/2 py-2 px-3  leading-tight focus:outline-none focus:shadow-outline"
                                    type="file" name="document" />
                                    <br></br>
                                    
                                    <button className=" font-bold text-center text-l text-frenchWhite p-3 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded m-1" type="submit">Ajouter une transaction</button>
                                
                                    {this.state.formFailed &&
                                    <div>
                                        <p className="text-badColor mb-3">
                                            Vous devez spécifier un montant et une description avant d'ajouter une transaction
                                        </p>
                                    </div>
                                    }
                                </form>
                            }


                                {!this.state.isCurrentFeePaid &&
                                    <p className="text-frenchWhite text-center p-2 bg-neutralColor inline-block rounded mb-3">
                                        Vous devez avoir payé votre cotisation pour accéder aux bilans annuels
                                    </p>
                                }
                                {this.state.isCurrentFeePaid &&
                                <table className="table-auto mx-auto w-3/4">
                                    <thead className="border-frenchBrown border-b-2">
                                        <tr>
                                            <th className="text-frenchWhite text-center p-2">Commentaire</th>
                                            <th className="text-frenchWhite text-center p-2">Montant</th>
                                            <th className="text-frenchWhite text-center p-2">Date</th>
                                            <th className="text-frenchWhite text-center p-2 ">Document</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.transactions && this.state.transactions.sort((a, b) => new Date(b.date) - new Date(a.date)).map((transaction)=> {
                                            return (
                                                <tr className="border-frenchBrown" key={transaction.id}>
                                                    <td className="text-frenchWhite  p-2 break-words text-left">{transaction.description}</td>
                                                    <td className={"text-center p-2 " + (transaction.amount > 0 ? " text-goodColor" : " text-badColor")}>{transaction.amount} €</td>
                                                    <td className="text-frenchWhite text-center p-2">{new Date(transaction.date).toLocaleDateString('fr-FR', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute:'numeric'})}</td>
                                                    <td className="text-frenchWhite text-center p-2">
                                                        {transaction.attachment &&
                                                            <a href={transaction.attachment} target="_blank" rel="noreferrer" className="text-frenchBrown underline">Télécharger</a>
                                                        }
                                                        {!transaction.attachment &&
                                                            <p className="text-neutralColor mb-4">Aucun</p>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            }

                            </div>   
                        

                 

                    </div>
                    */}
                        
                    

                    <div className="font-bold text-frenchWhite bg-frenchBlack/50 border-solid border-t-2 border-b-2 pb-5  border-frenchBrown ml-4 pt-4 mr-4 mt-4 mb-4">
                        {/* <hr className="border-frenchBrown border-2 ml-4 mr-4" /> */}
                        <h2 className="text-frenchWhite  underline text-2xl text-center p-2">
                            Cagnotte personnelle <span className="text-m">({me.lastName.toUpperCase()} {me.firstName})</span>
                        </h2>
                        
                        <h3 className="text-frenchWhite  text-xl text-center p-2">
                            Solde réel : <span className={"text-center p-2 " + (solde_reel === 0 ? "text-neutralColor" :solde_reel > 0 ? " text-goodColor" : " text-badColor")}>{solde_reel} €</span>
                        </h3>

                        <h3 className="text-frenchWhite  text-l text-center p-2">
                            Solde corrigé : <span className={"text-center p-2 " + (solde_corrigé === 0 ? "text-neutralColor" :solde_corrigé > 0 ? " text-goodColor" : " text-badColor")}>{solde_corrigé} €</span>
                        </h3>
                        
                        <h3 className="text-frenchWhite  text-l text-center p-2">
                            Opération en cours : <span className={"text-center p-2 " + (solde_cours === 0 ? "text-neutralColor" : solde_cours > 0 ? " text-goodColor" : " text-badColor")}>{solde_cours} €</span>
                        </h3>

                            {this.state.walletHistory.length > 0 &&
                            // Centered table/ maximum width
                                <table className="table-auto mx-auto w-3/4 	">
                                    <thead className="border-frenchBrown border-b-2">
                                        <tr>
                                            <th className="text-frenchWhite text-center p-2">Date</th>
                                            <th className="text-frenchWhite text-center p-2">Libellé</th>
                                            <th className="text-frenchWhite text-center p-2">Montant</th>
                                            <th className="text-frenchWhite text-center p-2 break-all ">Document</th>
                                            <th className="text-frenchWhite text-center p-2 break-all ">Statut</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.walletHistory
                                            .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
                                            .map((wallet, index) => {
                                            return (
                                                <tr className="border-frenchBrown" key={index}>
                                                    <td className="text-frenchWhite break-all	 text-center p-2">{new Date(wallet.datetime).toLocaleDateString('fr-FR', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute:'numeric'})}</td>

                                                    <td className="text-frenchWhite  p-2  text-left">{wallet.description}</td>
                                                    <td className={"text-center p-2 " + (wallet.amount > 0 ? " text-goodColor" : " text-badColor")}>{wallet.amount} €</td>
                                                    {wallet.document &&
                                                        <td className="text-frenchBrown text-center p-2 break-all  ">
                                                            <a href={wallet.document} target="_blank" rel="noreferrer">Télécharger</a>
                                                        </td>
                                                    }
                                                    {!wallet.document &&
                                                        <td className="text-gray text-center p-2">
                                                            Aucun
                                                        </td>
                                                    }
                                                    <td>
                                                        {wallet.validated &&
                                                            <p className="bg-goodColor rounded text-center p-1">Validé</p>
                                                        }
                                                        {!wallet.validated &&
                                                            <p onClick={() => {
                                                                // is admin
                                                                if(!Utils.isAdmin(me)) {
                                                                    return;
                                                                }
                                                                if(window.confirm("Voulez-vous vraiment valider cette opération ?")) {
                                                                    WebService.validateWalletOperation(wallet.id).then((response) => {
                                                                        if (response.status === 200) {
                                                                            let walletHistoryCopy = this.state.walletHistory;
                                                                            walletHistoryCopy = walletHistoryCopy.map((search) => {
                                                                                if(search.id === wallet.id) {
                                                                                    search.validated = true;
                                                                                }
                                                                                return search;
                                                                            });
                                                                            this.setState({walletHistory: walletHistoryCopy});
                                                                        }
                                                                    });
                                                                }
                                                            }} 
                                                            className="bg-waitingColor rounded text-center p-1 hover:cursor-pointer">Opération en cours</p>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            }
                            {this.state.walletHistory.length === 0 &&
                                <p className="text-frenchWhite text-center p-2 bg-neutralColor inline-block rounded">
                                    Aucun historique de cagnotte
                                </p>
                            }

                        
                    </div>
                </div >
            }
            </div>
            
        );
    }

}